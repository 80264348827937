<template>
  <app-collapse-item :is-visible="checkRequiredHostnameFragments(['hydro'])" :force-show="checkRequiredHostnameFragments(['hydro'])" v-if="contact && contact.note || !contact">
    <template #header>
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <sw-icon
          icon="AlignLeftIcon"
          style="margin-top: -.2rem"
        />

        <h6 class="mb-0">
          {{ $t('Note') }}
        </h6>

        <b-spinner
          v-if="!contact"
          type="grow"
          small
          class="mx-auto"
          variant="primary"
        />
      </div>
    </template>

    <p
      v-if="contact"
      class="mb-0"
      v-html="(contact.note || '').replaceAll('\n', '<br>')"
    />
  </app-collapse-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      contact: 'contact/contact',
    }),
  },
}
</script>
